// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.
// temporarily replacing "dev" configs from "dev" configs

export const environment = {
    version: '1.000.0',
    production: false,
    errMsg: 'Failed to complete. Please try again.',
    partner: false,
    config: {
        "haystackUrl": "https://silo.dev.service.75f.io/",
        "tagsUrl": "https://silo.dev.service.75f.io/v2/sitePointTags",
        "cpmUrl": "https://chiller-plant.dev.service.75f.io/",
        "alertsUrl": "https://alerts.dev.service.75f.io/",
        "pasUrl": `https://portfolio-analytics.dev.service.75f.io`,
        "auth": {
            "caretakerUrl": "https://caretaker.dev.service.75f.io/api/v1",
            "gatekeeperUrl": "https://gatekeeper.dev.service.75f.io/api/v1"
        }
    }
};